import { FC } from "react";
import MPU from "common/Layout/Adverts/MPU";
import { Wrapper } from "./ArticleX3PlusMPU.styled";
import Item from "Components/Item";
import { ArticleComponent } from "../types";
import {getCropData} from "../getCropData";

type Props = {
  data: ArticleComponent;
  mpu_id: number;
};

const ArticleX3PlusMPU: FC<Props> = ({ mpu_id, data }) => {
  const { articles } = data;

  if (!articles || articles.length < 3) {
    console.warn(
      "Insufficient articles available for ArticleX3PlusMPU",
      articles
    );
    return null;
  }

  return (
    <Wrapper>
      {articles.map((article, index) => {
        return (
          <Item
            key={index}
            size={3}
            mpu={true}
            article={article}
            cropData={getCropData(article, "twobyone")}
          />
        );
      })}
      <MPU className="three_item" num={mpu_id} />
    </Wrapper>
  );
};

export default ArticleX3PlusMPU;
